
import HomePage from '../pages/home.f7.html';
import AboutKoalaPage from '../pages/about-koala.f7.html';
import AboutKoalaFoodAppPage from '../pages/about-koala-food-app.f7.html';
import privacyPage from '../pages/privacy.f7.html';
import termsAndConditionsPage from '../pages/terms-and-conditions.f7.html';
import contactPage from '../pages/contact.f7.html';

import DynamicRoutePage from '../pages/dynamic-route.f7.html';
import RequestAndLoad from '../pages/request-and-load.f7.html';
import NotFoundPage from '../pages/404.f7.html';

var routes = [
  {
    path: '/',
    async: function ({ app, to, resolve }) {

      app.panel.close('.panel-left');
      resolve({
        context: null,
        component: HomePage,
      });
    }
  },
  {
    path: '/about-koala/',
    async: function ({ app, to, resolve }) {

      app.panel.close('.panel-left');
      resolve({
        context: null,
        component: AboutKoalaPage,
      });
    }
  },
  {
    path: '/about-koala-food-app/',
    async: function ({ app, to, resolve }) {

      app.panel.close('.panel-left');
      resolve({
        context: null,
        component: AboutKoalaFoodAppPage,
      });
    }
  },
  {
    path: '/privacy/',
    async: function ({ app, to, resolve }) {

      app.panel.close('.panel-left');
      resolve({
        context: null,
        component: privacyPage,
      });
    }
  },
  {
    path: '/terms-and-conditions/',
    async: function ({ app, to, resolve }) {

      app.panel.close('.panel-left');
      resolve({
        context: null,
        component: termsAndConditionsPage,
      });
    }
  },
  {
    path: '/contact/',
    async: function ({ app, to, resolve }) {

      app.panel.close('.panel-left');
      resolve({
        context: null,
        component: contactPage,
      });
    }
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function ({ router, to, resolve }) {
      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = to.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            props: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;