/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component() {
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="about">
    <!-- Top Navbar -->
    <div class="navbar navbar-large">
      <div class="navbar-bg">
        <div class="text-align-center">
          <img src="static/imgs/icon-logo.png" width="80"/>
        </div>
      </div>
      <div class="navbar-inner">
        <div class="left">
          <a href="#" class="link icon-only panel-open" data-panel="left">
            <i class="icon f7-icons if-not-md">menu</i>
            <i class="icon material-icons if-md">menu</i>
          </a>
        </div>
        <div class="title sliding">Contatto</div>
        <div class="title-large">
          <div class="title-large-text">Contatto</div>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="block-title">Contatto</div>

      <div class="block block-strong text-align-right">
        <div class="block block-strong">
          <p>
          <strong>IKARMA S.R.L.<br/>
          Via Almerico da Schio, 8<br/>
          20146 Milano (MI)<br/></strong>
          E-Mail: <a href="mailto:contact@koalafood.com">contact@koalafood.com</a>.
          </p>
        </div>
      </div>


    </div>
  </div>
  `
    }
    ;
}

framework7Component.id = 'c48dde8b7d';
export default framework7Component;