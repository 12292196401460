import $ from 'dom7';
import Framework7 from 'framework7/bundle';

// Import F7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.scss';


// Import Routes
import routes from './routes.js';
// Import Store
import store from './store.js';

// Import main app component
import App from '../app.f7.html';


var app = new Framework7({
  name: 'Koala Food', // App name
  theme: 'auto', // Automatic theme detection
  el: '#app', // App root element
  view: {
    stackPages: false,
    pushState: false,
    browserHistory: true,
    pushStateSeparator: 'page',
    pushStateRoot: location.href.split(location.host)[1].split('#!')[0],
    iosDynamicNavbar: false,
  },
  component: App, // App main component

  // App store
  store: store,
  // App routes
  routes: routes,
});