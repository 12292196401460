import _defineProperty from "@babel/runtime/helpers/defineProperty";

/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $f7 = _ref.$f7,
      $f7router = _ref.$f7router,
      $update = _ref.$update,
      $on = _ref.$on,
      $onBeforeMount = _ref.$onBeforeMount,
      $onMounted = _ref.$onMounted,
      $onBeforeUpdate = _ref.$onBeforeUpdate,
      $onUpdated = _ref.$onUpdated,
      $onBeforeUnmount = _ref.$onBeforeUnmount,
      $onUnmounted = _ref.$onUnmounted,
      $store = _ref.$store;
  $on('pageInit', function (e, page) {
    var _$f7$swiper$create;

    console.log('pageInit', page);
    var swiper = $f7.swiper.create('.swiper-container.slides', (_$f7$swiper$create = {
      lazy: {
        threshold: 50,
        observer: true,
        loadPrevNext: true,
        loadPrevNextAmount: 1
      },
      breakpoints: {
        340: {
          slidesPerView: 3,
          spaceBetween: 5
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 5
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 5
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 5
        }
      },

      /*scrollbar: {
        el: ".swiper-scrollbar",
        hide: false,
      },*/
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      pagination: {
        el: ".swiper-pagination",
        type: "fraction"
      }
    }, _defineProperty(_$f7$swiper$create, "lazy", true), _defineProperty(_$f7$swiper$create, "slidesPerView", 3), _defineProperty(_$f7$swiper$create, "spaceBetween", 5), _defineProperty(_$f7$swiper$create, "freeMode", false), _defineProperty(_$f7$swiper$create, "watchSlidesProgress", true), _defineProperty(_$f7$swiper$create, "watchSlidesVisibility", true), _$f7$swiper$create));
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="home">
    <!-- Top Navbar -->
    <div class="navbar navbar-large">
      <div class="navbar-bg">
        <div class="text-align-center">
          <img src="static/imgs/icon-logo.png" width="80"/>
        </div>
      </div>
      <div class="navbar-inner">
        <div class="left">
          <a href="#" class="link icon-only panel-open" data-panel="left">
            <i class="icon f7-icons if-not-md">menu</i>
            <i class="icon material-icons if-md">menu</i>
          </a>
        </div>
        <div class="title sliding">Koala Food</div>
        <div class="title-large">
          <div class="title-large-text">Koala Food</div>
        </div>
      </div>
    </div>
    <!-- Toolbar-->
    <!-- Scrollable page content-->
    <div class="page-content home">

      <div class="block">
        <div class="text-align-center">
          <p style="font-size:18px;font-weight:800">Salvare deliziosi cibi invenduti per meno!</p>
        </div>
      </div>
      
      <div class="fab fab-left">

        <div class="block">

        </div>

      </div>

      <div class="text-align-center">
        <a href="https://apps.apple.com/us/app/koala-food/id1519407227" class="external"><img src="static/imgs/apple-store-button.png" width="200"/></a>
      </div>

      <div class="fab fab-center-center">
      </div>

      <div class="fab fab-center-bottom">
      </div>

      <div class="fab fab-right-bottom">
        <p></p>
      </div>

      <div class="block" style="width:90%">

        <!-- Stores Slides -->
          <div class="swiper-container slides">
            <div class="swiper-wrapper">

              <div class="swiper-slide swiper-lazy" data-background="/static/slides/1.png?nocache=">

                <!-- Content inside slide -->
                <div style="position: absolute;left: 0%;right: 0%;top: auto;bottom: 0%;width: 100%;height: 60px;padding: 0px;">
                  <div class="row no-gap">

                    <div class="col-100" style="background:transparent;color:#fff;text-align: left;padding-left:10px">
                    </div>

                  </div>
                </div>
                <!-- Content inside slide -->

              </div>     



              <div class="swiper-slide swiper-lazy" data-background="/static/slides/2.png?nocache=">

                <!-- Content inside slide -->
                <div style="position: absolute;left: 0%;right: 0%;top: auto;bottom: 0%;width: 100%;height: 60px;padding: 0px;">
                  <div class="row no-gap">

                    <div class="col-100" style="background:transparent;color:#fff;text-align: left;padding-left:10px">
                    </div>

                  </div>
                </div>
                <!-- Content inside slide -->

              </div>    
            
              <div class="swiper-slide swiper-lazy" data-background="/static/slides/3.png?nocache=">

                <!-- Content inside slide -->
                <div style="position: absolute;left: 0%;right: 0%;top: auto;bottom: 0%;width: 100%;height: 60px;padding: 0px;">
                  <div class="row no-gap">

                    <div class="col-100" style="background:transparent;color:#fff;text-align: left;padding-left:10px">
                    </div>

                  </div>
                </div>
                <!-- Content inside slide -->

              </div>    


              <div class="swiper-slide swiper-lazy" data-background="/static/slides/4.png?nocache=">

                <!-- Content inside slide -->
                <div style="position: absolute;left: 0%;right: 0%;top: auto;bottom: 0%;width: 100%;height: 60px;padding: 0px;">
                  <div class="row no-gap">

                    <div class="col-100" style="background:transparent;color:#fff;text-align: left;padding-left:10px">
                    </div>

                  </div>
                </div>
                <!-- Content inside slide -->

              </div>    

              <div class="swiper-slide swiper-lazy" data-background="/static/slides/5.png?nocache=">

                <!-- Content inside slide -->
                <div style="position: absolute;left: 0%;right: 0%;top: auto;bottom: 0%;width: 100%;height: 60px;padding: 0px;">
                  <div class="row no-gap">

                    <div class="col-100" style="background:transparent;color:#fff;text-align: left;padding-left:10px">
                    </div>

                  </div>
                </div>
                <!-- Content inside slide -->

              </div>  
              
              
              <div class="swiper-slide swiper-lazy" data-background="/static/slides/6.png?nocache=">

                <!-- Content inside slide -->
                <div style="position: absolute;left: 0%;right: 0%;top: auto;bottom: 0%;width: 100%;height: 60px;padding: 0px;">
                  <div class="row no-gap">

                    <div class="col-100" style="background:transparent;color:#fff;text-align: left;padding-left:10px">
                    </div>

                  </div>
                </div>
                <!-- Content inside slide -->

              </div>  

              <div class="swiper-slide swiper-lazy" data-background="/static/slides/7.png?nocache=">

                <!-- Content inside slide -->
                <div style="position: absolute;left: 0%;right: 0%;top: auto;bottom: 0%;width: 100%;height: 60px;padding: 0px;">
                  <div class="row no-gap">

                    <div class="col-100" style="background:transparent;color:#fff;text-align: left;padding-left:10px">
                    </div>

                  </div>
                </div>
                <!-- Content inside slide -->

              </div>  

              <div class="swiper-slide swiper-lazy" data-background="/static/slides/8.png?nocache=">

                <!-- Content inside slide -->
                <div style="position: absolute;left: 0%;right: 0%;top: auto;bottom: 0%;width: 100%;height: 60px;padding: 0px;">
                  <div class="row no-gap">

                    <div class="col-100" style="background:transparent;color:#fff;text-align: left;padding-left:10px">
                    </div>

                  </div>
                </div>
                <!-- Content inside slide -->

              </div>  


            </div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>

          </div>
        <!-- Stores Slides -->
      </div>



    </div>
  </div>
`
    }
    ;
}

framework7Component.id = '7a7f310f54';
export default framework7Component;